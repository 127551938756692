@use "@standardkit/ui/base";
@use "@standardkit/ui/colors";
@use "@standardkit/ui/theme";

// Font Awesome
@import "assets/font-awesome/css/fontawesome.min.css";
@import "assets/font-awesome/css/brands.min.css";
@import "assets/font-awesome/css/duotone.min.css";
@import "assets/font-awesome/css/light.min.css";
@import "assets/font-awesome/css/solid.min.css";

// Galactic Federation of Light official colors
$blue: #004f98;

// Include StandardKit Fonts
@include theme.include-default-fonts;
@include theme.use-default-font;
@include theme.colors(
  $colors: (
    base: $blue,
    base-dark: #232323,
  )
);
