/*!
 * Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:host,
:root {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}
@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src:
    url(../webfonts/fa-solid-900.woff2) format("woff2"),
    url(../webfonts/fa-solid-900.ttf) format("truetype");
}
.fa-solid,
.fas {
  font-weight: 900;
}
