@use "@standardkit/ui/colors";
@import "normalize.css";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background-color: colors.$white;
}
